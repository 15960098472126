<template>
  <div class="row mx-md-0">
    <div class="col-12 p-md-3 py-3 px-0" :class="{'lightgrey_bg': isOdd}">
      <!--DESKTOP-->
      <div class="row m-0 align-items-center d-none d-md-flex position-relative">
        <input class="form-check-input position-absolute p-0" type="checkbox" v-model="isSelected">
        <div class="col-md-3 pe-0 font15 bold">
          <div class="row m-0 align-items-center py-1">
            <div class="col-auto">
              <IconSingleDonor />
            </div>
            <div class="col text-truncate">
              <span v-if="item.donationMadeBy">{{item.donationMadeBy.firstName}} {{item.donationMadeBy.lastName}}</span>
              <span v-else>{{item.user.firstName}} {{item.user.lastName}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-2 p-0 text-center font15">
          {{item.transactionReference}}
        </div>
        <div class="col-md-2 p-0 text-center font15">
          {{date}}
        </div>
        <div class="col-md-1 p-0 text-center font15 bold">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-md-2 p-0 text-center bold font15">
          {{item.paymentOption.method}}
        </div>
        <div class="col-md-2 p-0 text-center">
          <div class="row align-items-center justify-content-end">
            <div class="col-auto px-1">
              <Button color="green" btnText="VIEW" size="small" @click="view"/>
            </div>
            <div class="col-auto px-1">
              <Button color="gold" icon="arrow" size="xxsmall" v-if="item.is18a">
                <Icon18a size="size16" />
              </Button>
              <Button color="darkgrey" icon="arrow" size="xxsmall" v-else>
                <Icon18a size="size16" />
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey" icon="arrow" size="xxsmall" @buttonClicked="printReceipt">
                <IconPrint size="size12" />
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey" icon="arrow" size="xxsmall" @buttonClicked="downloadReceipt">
                <IconDownload size="size12" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!--END DESKTOP-->
      <!--MOBILE-->
      <div class="row mx-0 align-items-center d-md-none position-relative font13">
        <div class="col-3 p-0 text-center">
          {{item.transactionReference}}
        </div>
        <div class="col-3 p-0 text-center">
          {{date}}
        </div>
        <div class="col-3 p-0 text-center bold">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-3 p-0 text-center">
          <Button color="green" btnText="VIEW" size="small" @click="view"/>
        </div>
      </div>
      <!--END MOBILE-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconSingleDonor: defineAsyncComponent(() => import('@/components/icons/IconSingleDonor.vue')),
    Icon18a: defineAsyncComponent(() => import('@/components/icons/Icon18a.vue')),
    IconDownload: defineAsyncComponent(() => import('@/components/icons/IconDownload.vue')),
    IconPrint: defineAsyncComponent(() => import('@/components/icons/IconPrint.vue'))
  },
  name: 'PastDonationItem',
  props: ['item', 'thekey'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isSelected: false
    }
  },
  watch: {
    isSelected () {
      if (this.isSelected) {
        this.$emit('addSelected')
      } else {
        this.$emit('removeSelected')
      }
    }
  },
  computed: {
    ...mapGetters(['organisations']),
    theOrganisation () {
      const org = this.organisations.find(organisation => organisation.organisationID === this.item.organisationID)
      return org.registeredName
    },
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.transactionDate))
      return date.toLocaleString(DateTime.DATE_FULL)
    },
    isOdd () {
      return this.thekey % 2
    }
  },
  methods: {
    view () {
      this.$emit('showDetails')
    },
    downloadReceipt () {
      this.$emit('downloadReceipt')
    },
    printReceipt () {
      this.$emit('printReceipt')
    }
  }
}
</script>

<style scoped>
.dark_text {
  color: var(--normal-color-dark);
}
.position_absolute {
  position: absolute;
  left: -2rem;
}
.round {
  background-color: rgb(249, 250, 251);
  border-radius: 2rem;
}
.lightgrey_bg .round {
  background-color: #fff;
}
</style>
